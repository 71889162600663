// HERO COLORS

$primaryColor: #EB6E64;
$secondaryColor: #7F87DA;
$tertiaryColor: #E8B370;

// TYPOGRAPHY COLORS

$lightGray: #CCD6F6;
$graySeparator: #3E4A60;

// BOOTSTRAP OVERRIDE

$theme-colors: (
  "primary": #EB6E64
);
