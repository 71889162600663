@import '_typography.scss';
@import '_layout.scss';
@import '_customBS.scss';

html {
  background-color: #171A27;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@import "~bootstrap/scss/bootstrap.scss";
// @import "~@fortawesome/fontawesome-pro/css/all.css";

