@import "~bootstrap/scss/functions.scss";
@import "~bootstrap/scss/variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

$bgColor: #171A27;
$cardColor: #2C3140;

#root {
  background-color: $bgColor;
}

.navBar {
  height: 80px;
  background-color: transparentize($color: $bgColor, $amount: 0);
  box-shadow: 0px 3px 5px rgba($color: #000000, $alpha: .1);
}

.navbar-collapse.collapse.show {
  background-color: $bgColor;
  background-color: transparentize($color: $bgColor, $amount: 0);
  padding-bottom: 1.5rem;
}


.sectionContainer {
  padding: 100px 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

// #home {
//   padding: 125px 0;
//  }

.workContainer {
  padding: 2rem;
  border-radius: 2px;
  background-color: $cardColor;
  box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: .1);
  margin: 4rem 0;
}

.aboutRule {
  border-bottom: 2px solid $tertiaryColor;
  margin-bottom: 1rem;
  max-width: 300px;
}

.aboutImage {
  box-shadow: -10px 10px 0 transparentize($color: $primaryColor, $amount: .8);
  border: 4px solid $secondaryColor;
  border-radius: .25rem !important;
  width: 100%;
  height: auto;
}

/* ~~ Project Card Layout ~~ */

.projectListContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.projectContainer.card {
  background-color: $cardColor;
  padding: 2rem;
  margin: 1rem 0;
}

.projectDetails {
  display: flex;
  align-items: center;
}

.projectImage {
  height: 300px;
  width: 100%;
  border: 3px solid $secondaryColor;
  border-radius: .25rem;
  object-fit: cover;
  position: relative;
}

/* ~~ Work / Skills ~~ */

// Skills

.workSkills {
  margin: 2rem 0 0;
  skillStyle {
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  .workSkillsTech {
    @extend skillStyle;
    border-left: 2px solid $primaryColor;
  }
  .workSkillsDesign {
    @extend skillStyle;
    border-left: 2px solid $secondaryColor;
  }
  .workSkillsTools {
    @extend skillStyle;
    border-left: 2px solid $tertiaryColor;
  }

  p {
    margin-bottom: 7px;
  }

}

.tab-content {
  border-left: 2px solid #3E4A60;
  padding-left: 2rem;
}

.projectDesignBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @extend .workContainer;
  background-color: darken($color: $bgColor, $amount: 2.5%);

  h6 {
    margin-top: .6rem;
  }

  .svg-inline--fa {
    margin-right: 1rem;
  }

}

/* ~~~ Contact page ~~~ */

.contactSocials {
  margin: 1rem 0 3rem;
}


/* ~~~ MEDIA QUERIERS ~~~ */

@include media-breakpoint-down(md) {

  // when featured project image is stacked on card
  .projectImage {
    margin-bottom: 1rem;
  }

  // remove vertical rule from experience card
  .tab-content {
    border-top: 2px solid #3E4A60;
    border-left: none;
    padding-left: 0;
    padding-top: 2rem;
  }
}
