@import './colors.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Source+Code+Pro:wght@400;700&display=swap');
codeFont {
  font-family: 'Source Code Pro', monospace;
  font-style: normal;
  font-weight: 400;
  color: white;
}

sansFont {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: $lightGray;
}

primaryLinkHover {
  color: lighten($color: $primaryColor, $amount: 15%) !important;
  text-decoration: none;
}

h1, h2, h3, .h1, .h2, .h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700 !important;
  font-style: normal;
}

h4, h5, h6, .h4, .h5, .h6 {
  @extend codeFont;
  font-weight: normal !important;
}

/* ~~ Navigation ~~ */

.navbar-brand {
  @extend codeFont;
  font-weight: 700 !important;
  font-style: normal;
  cursor: pointer;
}

.nav-link {
  // React-Scroll's Link does not display pointer
  cursor: pointer;
  @extend codeFont;
  font-weight: normal;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  color: #CCD6F6 !important;
  &:hover {
    color: white !important;
  }
}

p {
  @extend sansFont;
  line-height: 1.7rem;
  letter-spacing: .02rem;
}

/* ~~ Home Section ~~ */

.homeHeroHello {
  @extend codeFont;
  font-weight: 700;
  color: $secondaryColor;
  letter-spacing: .25px;
}

.homeHeroName {
  @extend sansFont;
  font-size: 78px;
  font-weight: 700;
  font-kerning: none;
  letter-spacing: -.5px;
  color: white;
  line-height: 80px;
  margin: 1rem 0;
  margin-left: -4px;
}

.homeHeroTagline {
  @extend codeFont;
  font-weight: 700;
  letter-spacing: -1px;
  color: $primaryColor;
}

/* ~~ Experience ~~ */

// work section
.workRole {
  @extend sansFont;
  color: $secondaryColor;
  margin-top: 1.5rem;
  font-weight: 700;
  font-size: 1.1rem;

  &:first-child {
    margin-top: 0;
  }
}

.workDates {
  @extend codeFont;
  color: $secondaryColor;
}

.workPosition {
  color: white;
}

.workCompany {
  color: $tertiaryColor;
  text-decoration: underline;
  a:hover {
    @extend primaryLinkHover;
  }
}

// bullets
li {
  @extend p;
  margin-bottom: .75rem !important;
}

// skill subheads
.workSkills p strong {
  color: white;
}

/* ~~ Project Card Typography */

// technologies listed on each project
.projectTech {
  @extend codeFont;
  color: $secondaryColor;
  font-size: .85rem;
  &:hover {
    color: darken($color: $secondaryColor, $amount: 5%)
  }
}

// technology list separator
.projectTechSep {
  @extend .projectTech;
  color: $graySeparator;
  margin: 0 4px;
}

// 'featured project' line
.projectFeatured {
  @extend codeFont;
  color: $primaryColor;
  font-size: .85rem;
  margin-bottom: .5rem;
}


.projectLinks {
  @extend codeFont;
  color: $primaryColor;
  font-weight: 700;
  margin-top: auto !important;
  // project demo and GH links
  .iconLinks {
    margin-right: 2px;
    .fa-secondary {
      color: $secondaryColor;
      opacity: 1;
    }
  }
  a:hover {
    @extend primaryLinkHover;
  }
}

.projectDesignBanner {
  .h6 {
    font-size: .8rem;
  }
  .projectDesignLink {
    @extend codeFont;
    @extend h6;
    font-weight: 700 !important;
    a:hover {
      @extend primaryLinkHover;
    }
  }
  .fa-primary {
    color: $tertiaryColor;
  }
  .fa-secondary {
    color: $secondaryColor;
    opacity: .8;
  }
}

#experience .projectDesignBanner {
  .fa-primary {
    color: $tertiaryColor;
    opacity: 1;
  }
  .fa-secondary {
    color: $secondaryColor;
    opacity: .5;
  }
}

.contactSocials {
  a {
    margin: .5rem;
    &:hover {
      @extend primaryLinkHover;
    }
  }
}

.emailBtn {
  @extend codeFont;
  background-color: transparentize($color: $secondaryColor, $amount: 1);
  border: 2px solid $secondaryColor !important;
  border-radius: 4px;
  padding: 1.25rem 1.25rem 1.15rem;
  font-size: 1.1rem;
  margin-bottom: 3rem;

  &:hover {
    background-color: transparentize($color: $secondaryColor, $amount: .85);
  }

  .fa-envelope {
    margin-right: 1rem;
    color: $primaryColor;
  }
}
